<template>
  <div class="h-screen flex w-full bg-img">
      <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
          <vx-card>
              <div slot="no-body" class="full-page-bg-color">
                  <div class="vx-row">
                      <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                          <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
                      </div>
                      <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                          <div class="p-8" v-if="recoverDone">
                              <div class="vx-card__title mb-8">
                                  <h4 class="mb-4">Email Sent!</h4>
                              </div>
                            <tp-cardtext v-bind:min-height="150">
                              <p>If the username / email address you have entered is registered to an account, you will receive an email with instructions to reset your password.</p>
                            </tp-cardtext>
                            <vs-button 
                              class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6" 
                              to="/login">Login Page
                            </vs-button>
                          </div>
                          <div class="p-8" v-if="!recoverDone">
                              <div class="vx-card__title mb-8">
                                  <h4 class="mb-4">Recover your password</h4>
                                  <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                              </div>
                              <vs-input type="email"
                                v-validate="'required|email'"
                                data-vv-validate-on="blur"
                                label-placeholder="Email"
                                name="email"
                                v-model="email"
                                class="w-full" 
                              />
                              <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                              <div class="w-full mb-8"></div>
                              

                              <vs-button type="border" to="/login" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                              <vs-button 
                                class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0" 
                                :disabled="!validateForm"
                                @click="recoverPassword">Recover Password
                              </vs-button>

                          </div>
                      </div>
                  </div>
              </div>
          </vx-card>
      </div>
  </div>
</template>

<script>
import TpCardtext from '../../components/travio-pro/TpCardtext'

export default {
  components :{
    TpCardtext
  },
  data () {
    return {
      email: '',
      recoverDone: false
    }
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any()
      );
    },
  },
  methods : {
    recoverPassword () {
      this.$vs.loading();
      this.$http.post('api/auth/forgotpassword', { email : this.email })
        .then(response => {
          this.recoverDone = true;
        })
        .catch(errors => {
          this.$vs.notify({
            title: 'Forgotten Password',
            text: `Unable to recover password ${errors.response.data.detail}`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        })
        .finally(()=> this.$vs.loading.close());
    }
  }
}
</script>

<style>

</style>